import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Shivam Khandelwal </span>
            from <span className="purple"> Gurgoan, India.</span>
            <br />
            I am currently employed as a Senior Mobile Application Developer.
            <br />
            I have completed Master in Computer Application from JNU (Jaipur).
            <br />
          </p>
          <p style={{ color: "rgb(155 126 172)" }}>
            " Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Shivam Khandelwal</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
