import React, { useEffect } from "react";
import "./tech.css";
import { Col, Row } from "react-bootstrap";
import RN from "../../Assets/Icons/rn.svg";
import TS from "../../Assets/Icons/ts.png";
import JS from "../../Assets/Icons/js.webp";
import Android from "../../Assets/Icons/android.png";
import iOS from "../../Assets/Icons/ios.png";
import iOT from "../../Assets/Icons/iot.png";
import NodeJs from "../../Assets/Icons/node.png";
import Firebase from "../../Assets/Icons/firebase.png";
import Dart from "../../Assets/Icons/dart.svg";
import Flutter from "../../Assets/Icons/flutter.svg";
import Kotlin from "../../Assets/Icons/kotlin.svg";
import Aws from "../../Assets/Icons/aws.png";
import Appcenter from "../../Assets/Icons/appcenter.png";
import cicd from "../../Assets/Icons/cicd.png";
import vision from "../../Assets/Icons/vision.png";
import ar from "../../Assets/Icons/ar.webp";
import vr from "../../Assets/Icons/vr.png";
import gcp from "../../Assets/Icons/gcp.png";
import redux from "../../Assets/Icons/redux.svg";
import npm from "../../Assets/Icons/npm.png";
import yarn from "../../Assets/Icons/yarn.png";
import graphql from "../../Assets/Icons/graphql.png";
import Realm from "../../Assets/Icons/realm.png";
import contextApi from "../../Assets/Icons/contextapi.png";
import Mobx from "../../Assets/Icons/mobx.png";
import rnweb from "../../Assets/Icons/rnweb.png";

const technologiesData = [
  { name: "React-Native", icon: RN },
  { name: "TypeScript", icon: TS },
  { name: "JavaScript", icon: JS },
  { name: "Android", icon: Android },
  { name: "iOS", icon: iOS },
  { name: "RN Web", icon: rnweb },
  { name: "IOT", icon: iOT },
  { name: 'NPM', icon: npm },
  { name: 'Yarn', icon: yarn },
  { name: "Node.js", icon: NodeJs },
  { name: "Firebase", icon: Firebase },
  { name: 'Dart', icon: Dart },
  { name: 'Flutter', icon: Flutter },
  { name: 'Kotlin', icon: Kotlin },
  { name: 'AWS', icon: Aws },
  { name: 'App Center', icon: Appcenter },
  { name: 'CI/CD', icon:  cicd},
  { name: 'google vision', icon: vision },
  { name: 'React AR', icon: ar },
  { name: 'React VR', icon: vr },
  { name: 'Google Cloud (GCP)', icon: gcp},
  { name: 'Redux', icon: redux },
  { name: 'Realm', icon: Realm },
  { name: 'Mobx', icon: Mobx },
  { name: 'Context Api', icon: contextApi },
  { name: 'GraphQL', icon: graphql },
];

// const technologiesData = [
//   { name: 'React', icon: 'react-icon.png' },
//   { name: 'JavaScript', icon: 'js-icon.png' },
//   { name: 'CSS', icon: 'css-icon.png' },
//   { name: 'HTML', icon: 'html-icon.png' },
//   { name: 'Node.js', icon: 'node-icon.png' },
//   { name: 'Express', icon: 'express-icon.png' },
//   { name: 'MongoDB', icon: 'mongodb-icon.png' },
//   { name: 'Git', icon: 'git-icon.png' },
//   { name: 'NPM', icon: 'npm-icon.png' },
// ];

const Technologies = () => {
  useEffect(() => {
    const techItems = document.querySelectorAll(".tech-item");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    techItems.forEach((item) => observer.observe(item));

    return () => {
      techItems.forEach((item) => observer.unobserve(item));
    };
  }, []);

  return (
    <div className="technologies-section">
      <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>
      Using a combination of cutting-edge technologies and reliable open-source software I build user-focused, performant apps and websites for smartphones, tablets, and desktops.
      <Row style={{ justifyContent: "center", paddingBottom: "40px" }}>
          {technologiesData.map((tech, index) => (
            <Col key={index} xs={8} md={2} className="tech-icons">
              <div key={index} className="tech-item">
                <img src={tech.icon} alt={tech.name} className="tech-icon" />
                <p className="tech-name">{tech.name}</p>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default Technologies;
