import React, { useRef, useState } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import "./form.css";
import EnquireNow from "./enquireNow";
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram, FaInfoCircle, FaPhoneAlt } from 'react-icons/fa';
import { DiGithub } from "react-icons/di";
// import "./App.css"; // Import your CSS file for styling

function FormPage() {
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");
    const [result, setResult] = React.useState("");

    const form = useRef();

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);
        const name = formData.get("name");
        const email = formData.get("email");
        const number = formData.get('number');
        const message = formData.get("message");

        if (!name || !email || !message || !number) {
            setError("All fields are required.*");
            return;
        }
        setSubmitting(true); // Set submitting state to true
        setError(""); // Reset error state

        formData.append("access_key", "08d27d4f-13a6-48ea-98f7-e5f3742c19a8");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            console.log("Success", response);
            setSubmitted(true); // Set the submitted state to true
            event.target.reset();
        } else {
            setResult(data.message);
        }
        setSubmitting(false); // Reset submitting state
    };


    return (
        <div className="Contact-content container">
            <div className="grid-innerview">
                <div className="Contact-form container">
                    <h1>Contact me</h1>
                    <p>If you have a request or question, don't hesitate to use the form.</p>
                    <Form ref={form} onSubmit={onSubmit}>
                        <Row>
                            <Col>
                                <Form.Control type="text" placeholder="Name" name="name" required />
                            </Col>
                            <Col>
                                <Form.Control type="email" placeholder="E-Mail" name="email" required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control
                                    type="tel"
                                    placeholder="Mobile Number"
                                    name="number"
                                    required
                                />
                            </Col>
                        </Row>
                        <Form.Control type="text" placeholder="Subject" name="subject" required />
                        <Form.Control id="Contact-message" type="textarea" placeholder="Message" name="message" required />
                        <Form.Check type="switch" id="custom-switch" label="I accept the conditions set out in the privacy policy." href="#" value="privacy-check" required />
                        <div className="Contact-button">
                            <Button id="Contact-submit" type="submit" value="Send"> Send message! </Button>
                        </div>
                    </Form>
                </div>
                <div style={{
                    alignSelf: 'center',
                }}>
                </div>
            </div>
            <div className="Contact-social">
                <h2>Let's get social</h2>
                <div className="Contact-social-btns">
                    <Button size="lg" className="Contact-social-btn" href="https://github.com/shivam4ukhandelwal" target="_blank">
                     <DiGithub  size={30} style={{marginRight: 10}} />   GitHub</Button>
                    <Button size="lg" className="Contact-social-btn" href="https://www.linkedin.com/in/shivam-khandelwal/" target="_blank" style={{
                        gap: 20,
                        rowGap: 20,
                     }}>
                        <FaLinkedin size={30} style={{marginRight: 10}}/>
                        {`     LinkedIn`}</Button>
                </div>
            </div>
        </div>
    );
}

export default FormPage;