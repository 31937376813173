import React, { useEffect, useRef } from "react";
// import './ProjectCard.css'; // Import your CSS file
// import { motion, useAnimation, useInView } from "framer-motion";
import { Card, Row, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import "./ProjectCard.css";
// import { ImageCard } from "../../animatedCard";
import { FaAndroid } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { DiAndroid } from "react-icons/di";
import { IoIosStats } from "react-icons/io";
import { PiProjectorScreen } from "react-icons/pi";

const ProjectCard = ({ project, index }) => {
//   const controls = useAnimation();
  const isLTR = index % 2;
//   const cardDirection = isLTR ? "ltr" : "rtl";

//   const { ref: caseRef, inView } = useInView({
//     triggerOnce: false, // Animate only once
//     threshold: 0.1, // Trigger when 10% of the element is in view
//   });

  //   const cardRef = useRef(null);
  //   const { ref, inView } = useInView({
  //     triggerOnce: true,
  //     rootMargin: "-50px", // Adjust as needed
  //   });

  //   useEffect(() => {
  //     if (inView) {
  //       const cardElement = cardRef.current;
  //       cardElement.classList.add("animated");
  //       // Add specific animation class based on cardDirection
  //       cardElement.classList.add(
  //         cardDirection === "ltr" ? "animate-ltr" : "animate-rtl"
  //       );
  //     }
  //   }, [inView, cardDirection]);

  return (
    <Card className="project-card-view" style={{ margin: 20, padding: 0 }}>
      <Row
        md={1}
        className={isLTR ? "project-card flex-row-reverse" : "project-card"}
      >
        <Col md={5}>
        {
        project?.image ? 
        <img src={project?.image} style={{width: '40%', padding: 0}} alt={"project-image"} />
        : <></>
      }
            {/* <ImageCard image={project.image} hueA={hueA} hueB={hueB} /> */}
        </Col>
        <Col md={7}>
          <Card.Body>
            <div style={{
              gap: '20px',
              margin:20,
            }}>
           <text className="project-name5">{project.name}</text>
           <Card.Text>
            {project.description}
           </Card.Text>
           </div>
           <div>
           {project.downloadLink ? 
            <Button
              variant="primary"
              href={project.downloadLink}
              onClick={() => window.open(project.downloadLink, "_blank")}
              target="_blank"
              style={{ marginLeft: "10px", width: '200px' }}
            >
              <PiProjectorScreen />
              App Download Page
            </Button> : <></>
          }
            {project.androidLink ?
              <Button
              variant="primary"
              href={project?.androidLink}
              onClick={() => window.open(project.androidLink, "_blank")}
              target="_blank"
              style={{ marginLeft: "10px", width: '200px' }}

            >
              <DiAndroid /> &nbsp;
              {"Android App"}
            </Button> : <></>
            } 
        {project.iOSLink ?
              <Button
              variant="primary"
              onClick={() => window.open(project.iOSLink, "_blank")}
              href={project?.iOSLink}
              target="_blank"
              style={{ marginLeft: "10px", width: '200px' }}

            >
              <IoIosStats /> &nbsp;
              {"iOS App"}
            </Button> : <></>
            } 
           </div>
            {/* <text className="typewritter">
              This is a wider card with supporting text below as a natural
              lead-in   to additional content. This content is a little bit
              longer.
            </text> */}
          </Card.Body>
          {/* <text>{index}</text> */}
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectCard;
