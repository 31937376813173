import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaPhone, FaInfoCircle, FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import Particle from "../Particle";
import FormPage from './form';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobile: '',
    solution: '',
    source: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted:', formData);
  };

  return (
    <Container fluid className="about-section">
      {/* <Particle /> */}
      <FormPage />
      <div style={{paddingBottom: '200px'}} />
      {/* <Row>
        <Col md={8}>
          <h2>Write to us</h2>
          <Form onSubmit={handleSubmit}>
            <Col md={5}>
            <Form.Group controlId="formFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="Full Name"
              />
              
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Valid email address"
              />
            </Form.Group>
            </Col>
            <Form.Group controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Valid contact number"
              />
            </Form.Group>
            <Form.Group controlId="formSolution">
              <Form.Label>Solutions</Form.Label>
              <Form.Control
                as="select"
                name="solution"
                value={formData.solution}
                onChange={handleChange}
              >
                <option value="">Select Here</option>
                <option value="solution1">Solution 1</option>
                <option value="solution2">Solution 2</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formSource">
              <Form.Label>Source</Form.Label>
              <Form.Control
                as="select"
                name="source"
                value={formData.source}
                onChange={handleChange}
              >
                <option value="">Select Here</option>
                <option value="source1">Source 1</option>
                <option value="source2">Source 2</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your message here"
              />
            </Form.Group>
            <Form.Group controlId="formCaptcha">
              <Form.Check type="checkbox" label="I'm not a robot" />
            </Form.Group>
            <Button variant="primary" type="submit">Send</Button>
          </Form>
        </Col>
        <Col md={4}>
          <h2>Enquire now</h2>
          <div className="mb-3">
            <FaInfoCircle /> For General Queries
            <br />
            <a href="mailto:info@tftus.com">info@tftus.com</a>
          </div>
          <div className="mb-3">
            <FaPhone /> Phone Number
            <br />
            <a href="tel:+911242807000">+91-124-280-7000</a>
          </div>
          <div className="mb-3">
            <FaInfoCircle /> For Career Related Queries
            <br />
            <a href="mailto:recruiter@tftus.com">recruiter@tftus.com</a>
          </div>
          <h3>Find us on</h3>
          <div className="d-flex justify-content-around">
            <a href="#"><FaFacebookF /></a>
            <a href="#"><FaLinkedinIn /></a>
            <a href="#"><FaTwitter /></a>
            <a href="#"><FaInstagram /></a>
          </div>
        </Col>
      </Row> */}
    </Container>
  );
};

export default ContactForm;
