import React from "react";
import { 
  MdPeople, MdGpsFixed, MdLocalHospital, MdLocalTaxi, MdSchool,
  MdWork, MdBuild, MdStorage, MdDeveloperMode, MdDevices, MdFitnessCenter,
  MdSports, MdBlock, MdBusinessCenter, MdShoppingCart, MdHome, MdEvent,
  MdSecurity, MdPersonSearch, MdPayment, MdStore, MdLibraryBooks, MdSchool as MdClass,
  MdSportsGolf, MdSportsFootball, MdImportantDevices 
} from 'react-icons/md';

const Services = {
  "Multi User React Native Mobile App Developer": <MdPeople />,
  "GPS Based React Native Mobile App Developer": <MdGpsFixed />,
  "Health Services React Native Mobile App Developer": <MdLocalHospital />,
  "Uber Clone React Native Mobile App Developer": <MdLocalTaxi />,
  "Education Industry Mobile App Developer": <MdSchool />,
  "Worker and Jobs Management Mobile App Developer": <MdWork />,
  "Custom Flutter Mobile App Developer": <MdBuild />,
  "Mobile App Developer with Firebase Backend": <MdStorage />,
  "Mobile App Developer with Custom Backend (Node.js, Laravel, Java, Django, and .NET)": <MdDeveloperMode />,
  "Native Android Mobile App Developer with Java": <MdDevices />,
  "Native Android Mobile App Developer with KOTLIN": <MdDevices />,
  "Native iOS Mobile App Developer with Objective C": <MdDevices />,
  "Native iOS Mobile App Developer with Swift": <MdDevices />,
  "React Native Mobile App Developer for Golf Mobile App": <MdSportsGolf />,
  "React Native Mobile App Developer for Education Mobile App": <MdSchool />,
  "React Native Mobile App Developer for Sports Mobile App": <MdSports />,
  "React Native Mobile App Developer for Fitness Mobile App": <MdFitnessCenter />,
  "React Native Mobile App Developer for Blockchain Mobile App": <MdBlock />,
  "React Native Mobile App Developer for Consulting Company Mobile App": <MdBusinessCenter />,
  "React Native Mobile App Developer for E-Commerce Mobile App": <MdShoppingCart />,
  "React Native Mobile App Developer for Real Estate Mobile App": <MdHome />,
  "React Native Mobile App Developer for Islamic Mobile App": <MdImportantDevices />,
  "React Native Mobile App Developer for Religious Mobile App": <MdImportantDevices />,
  "React Native Mobile App Developer for Events Mobile App": <MdEvent />,
  "React Native Mobile App Developer for Management Mobile App": <MdBusinessCenter />,
  "React Native Mobile App Developer for HR Mobile App": <MdWork />,
  "React Native Mobile App Developer for Job Finder Mobile App": <MdPersonSearch />,
  "React Native Mobile App Developer for IOT Mobile App": <MdImportantDevices />,
  "React Native Mobile App Developer for Artificial Intelligence Mobile App": <MdImportantDevices />,
  "React Native Mobile App Developer for Security Mobile App": <MdSecurity />,
  "React Native Mobile App Developer for Digital Agency Mobile App": <MdImportantDevices />,
  "React Native Mobile App Developer for Construction Mobile App": <MdBuild />,
  "React Native Mobile App Developer for Savings Mobile App": <MdPayment />,
  "React Native Mobile App Developer for Payment Transfer Mobile App": <MdPayment />,
  "React Native Mobile App Developer for Clothes Shop Mobile App": <MdStore />,
  "React Native Mobile App Developer for School Mobile App": <MdClass />,
  "React Native Mobile App Developer for Online Learning Mobile App": <MdLibraryBooks />,
  "Flutter Mobile App Developer for Golf Mobile App": <MdSportsGolf />,
  "Flutter Mobile App Developer for Education Mobile App": <MdSchool />,
  "Flutter Mobile App Developer for Sports Mobile App": <MdSports />,
  "Flutter Mobile App Developer for Fitness Mobile App": <MdFitnessCenter />,
  "Flutter Mobile App Developer for Blockchain Mobile App": <MdBlock />,
  "Flutter Mobile App Developer for Consulting Company Mobile App": <MdBusinessCenter />,
  "Flutter Mobile App Developer for E-Commerce Mobile App": <MdShoppingCart />,
  "Flutter Mobile App Developer for Real Estate Mobile App": <MdHome />,
  "Flutter Mobile App Developer for Islamic Mobile App": <MdImportantDevices />,
  "Flutter Mobile App Developer for Religious Mobile App": <MdImportantDevices />,
  "Flutter Mobile App Developer for Events Mobile App": <MdEvent />,
  "Flutter Mobile App Developer for Management Mobile App": <MdBusinessCenter />,
  "Flutter Mobile App Developer for HR Mobile App": <MdWork />,
  "Flutter Mobile App Developer for Job Finder Mobile App": <MdPersonSearch />,
  "Flutter Mobile App Developer for IOT Mobile App": <MdImportantDevices />
};
export default Services;

// {
//     "services": [
//       "Multi User React Native Mobile App Developer",
//       "GPS Based React Native Mobile App Developer",
//       "Health Services React Native Mobile App Developer",
//       "Uber Clone React Native Mobile App Developer",
//       "Education Industry Mobile App Developer",
//       "Worker and Jobs Management Mobile App Developer",
//       "Custom Flutter Mobile App Developer",
//       "Mobile App Developer with Firebase Backend",
//       "Mobile App Developer with Custom Backend (Node.js, Laravel, Java, Django, and .NET)",
//       "Native Android Mobile App Developer with Java",
//       "Native Android Mobile App Developer with KOTLIN",
//       "Native iOS Mobile App Developer with Objective C",
//       "Native iOS Mobile App Developer with Swift",
//       "React Native Mobile App Developer for Golf Mobile App",
//       "React Native Mobile App Developer for Education Mobile App",
//       "React Native Mobile App Developer for Sports Mobile App",
//       "React Native Mobile App Developer for Fitness Mobile App",
//       "React Native Mobile App Developer for Blockchain Mobile App",
//       "React Native Mobile App Developer for Consulting Company Mobile App",
//       "React Native Mobile App Developer for E-Commerce Mobile App",
//       "React Native Mobile App Developer for Real Estate Mobile App",
//       "React Native Mobile App Developer for Islamic Mobile App",
//       "React Native Mobile App Developer for Religious Mobile App",
//       "React Native Mobile App Developer for Events Mobile App",
//       "React Native Mobile App Developer for Management Mobile App",
//       "React Native Mobile App Developer for HR Mobile App",
//       "React Native Mobile App Developer for Job Finder Mobile App",
//       "React Native Mobile App Developer for IOT Mobile App",
//       "React Native Mobile App Developer for Artificial Intelligence Mobile App",
//       "React Native Mobile App Developer for Security Mobile App",
//       "React Native Mobile App Developer for Digital Agency Mobile App",
//       "React Native Mobile App Developer for Construction Mobile App",
//       "React Native Mobile App Developer for Savings Mobile App",
//       "React Native Mobile App Developer for Payment Transfer Mobile App",
//       "React Native Mobile App Developer for Clothes Shop Mobile App",
//       "React Native Mobile App Developer for School Mobile App",
//       "React Native Mobile App Developer for Online Learning Mobile App",
//       "Flutter Mobile App Developer for Golf Mobile App",
//       "Flutter Mobile App Developer for Education Mobile App",
//       "Flutter Mobile App Developer for Sports Mobile App",
//       "Flutter Mobile App Developer for Fitness Mobile App",
//       "Flutter Mobile App Developer for Blockchain Mobile App",
//       "Flutter Mobile App Developer for Consulting Company Mobile App",
//       "Flutter Mobile App Developer for E-Commerce Mobile App",
//       "Flutter Mobile App Developer for Real Estate Mobile App",
//       "Flutter Mobile App Developer for Islamic Mobile App",
//       "Flutter Mobile App Developer for Religious Mobile App",
//       "Flutter Mobile App Developer for Events Mobile App",
//       "Flutter Mobile App Developer for Management Mobile App",
//       "Flutter Mobile App Developer for HR Mobile App",
//       "Flutter Mobile App Developer for Job Finder Mobile App",
//       "Flutter Mobile App Developer for IOT Mobile App"
//     ]
//   }
  

